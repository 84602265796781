import React from "react"
import axios from "api"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"

export const AuthContext = React.createContext()

function AuthProvider(props) {
  // code for pre-loading the user's information if we have their token in
  // localStorage goes here
  // 🚨 this is the important bit.
  // Normally your provider components render the context provider with a value.
  // But we post-pone rendering any of the children until after we've determined
  // whether or not we have a user token and if we do, then we render a spinner
  // while we go retrieve that user's information.
  const [waiting, setWaiting] = React.useState(true)
  const [data, setData] = React.useState({})
  const [error, setError] = React.useState(false)
  const history = useHistory()

  React.useEffect(() => {
    const dataString = localStorage.getItem("data")
    if (dataString) {
      const data = JSON.parse(dataString)
      setData(data)
    }

    const timer = setTimeout(() => setWaiting(false), 0)
    return () => clearTimeout(timer)
  }, [setData])

  if (waiting) {
    return <span>loading...</span>
  }

  const saveData = ({ data }) => {
    localStorage.setItem("data", JSON.stringify(data))
    setData(data)
  }

  const login = (user) => {
    return axios
      .post("auth/login", { user })
      .then((res) => {
        if (res.data.user.role === 1) {
          return saveData(res)
        }
        return toast.error("غير مخول لك بالدخول")
      })
      .catch((serverError) => {
        setError(serverError)
        toast.error(serverError)
      })
  } // make a login request
  const forgot = (user) => {
    return axios
      .post("auth/password", { user })
      .then((response) => {
        localStorage.setItem("data", JSON.stringify(response.data))
        toast.success(response.data.message)
        setData(response.data)
      })
      .catch((serverError) => {
        setError(serverError)
        toast.error(serverError)
      })
  }
  const reset = (id, values) => {
    return axios
      .put(`passwords/${id}`, { params: values })
      .then((response) => {
        toast.success(response.data.message)
      })
      .catch((serverError) => {
        setError(serverError)
        toast.error(serverError)
      })
  }

  const register = () => {} // register the user

  const logout = () => {
    localStorage.setItem("data", JSON.stringify({}))
    setData({})
    history.push("/")
  } // clear the token in localStorage and the user data

  // note, I'm not bothering to optimize this `value` with React.useMemo here
  // because this is the top-most component rendered in our app and it will very
  // rarely re-render/cause a performance problem.
  return (
    <AuthContext.Provider
      value={{ data, error, login, forgot, logout, register, reset }}
      {...props}
    />
  )
}

export default AuthProvider
