import Axios from "axios"
import Qs from "qs"

function getAccessToken() {
  const data = JSON.parse(localStorage.getItem("data") || "{}")
  return data.token
}

const Axiosheaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
  locale: "ar",
}

export const baseURL = process.env.REACT_APP_API

const http = Axios.create({
  baseURL,
  headers: Axiosheaders,
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: "brackets" }),
})

http.interceptors.request.use((config) => {
  const token = getAccessToken()

  if (token) {
    config.headers["Authorization"] = "Bearer " + token
  }
  // config.headers['Content-Type'] = 'application/json';
  return config
}, null)

http.interceptors.response.use(null, ({ response }) => {
  let errorMessage = "لا يوجد اتصال بالانترنت"
  const hasAData = JSON.parse(localStorage.getItem("data"))

  if (response) {
    if (response.status === 401) {
      localStorage.clear()
      if (hasAData) window.location.reload()
    }
    errorMessage =
      response.data.error && response.data.error.message
        ? response.data.error.message
        : response.data.error
  }

  return Promise.reject(errorMessage)
})

export function generateBlob(url, config = { responseType: "blob" }) {
  const request = http.get(url, config)
  request.then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", "file.pdf")
    document.body.appendChild(link)
    link.click()
  })
  return request
}

export default http
